import React from 'react';

import Container from 'components/Container/Container';
import Hero from 'components/Hero/Hero';
import Layout from 'components/Layout';
import Seo from 'components/Seo';
import TextLayoutContainer from 'components/TextLayoutContainer/TextLayoutContainer';

import { banner } from 'styles/pages/common.module.scss';

const TermsPage = () => (
  <Layout fullWidth>
    <Seo title="Όροι χρήσης" />
    <Hero title="ΟΡΟΙ ΧΡΗΣΗΣ" containerClass={banner} />
    <Container withPadding>
      <TextLayoutContainer>
        <h2>1. ΕΙΣΑΓΩΓΗ</h2>
        <p>
          Το nicolasjewelry.gr είναι ένα ηλεκτρονικό κατάστημα διάθεσης
          προϊόντων κοσμημάτων και ρολογιών σε προσιτές τιμές, μέσω Διαδικτύου.
          Το ηλεκτρονικό αυτό κατάστημα ανήκει στην ατομική επιχείρηση Νίκος
          Δούλος με Α.Φ.Μ 133097095 και υπάγεται στην Δ.Ο.Υ Αμαλιαδας. Ο
          επισκέπτης/χρήστης αυτού του δικτυακού τόπου καλείται να διαβάσει
          προσεκτικά τους όρους χρήσης. Αυτοί οι όροι ισχύουν σε κάθε συναλλαγή
          μεταξύ του nicolasjewelry.gr και οποιουδήποτε προσώπου υποβάλλει μία
          ηλεκτρονική παραγγελία για προμήθεια των προϊόντων που διατίθενται σε
          αυτό το ηλεκτρονικό κατάστημα.
        </p>
        <h2>2. ΠΑΡΕΧΟΜΕΝΕΣ ΠΛΗΡΟΦΟΡΙΕΣ / ΠΡΟΪΟΝΤΑ</h2>
        <p>
          Το nicolasjewelry.gr δεσμεύεται ως προς την εγκυρότητα και ποιότητα
          των πληροφοριών που παρέχονται σε αυτό το δικτυακό τόπο, υπό την
          επιφύλαξη τυχόν τεχνικών ή τυπογραφικών λαθών. Επίσης δεσμεύεται για
          την άριστη ποιότητα των προϊόντων που διατίθενται σε αυτόν το δικτυακό
          τόπο. Σκοπός του nicolasjewelry.gr είναι κάθε παραγγελία να σας
          ικανοποιεί 100%. Επίσης, σας δίνουμε τη δυνατότητα να επιστρέψετε
          όποιο είδος επιθυμείτε. (βλέπε ενότητα Επιστροφές Προϊόντων).
        </p>{' '}
        <h2>3. ΤΡΟΠΟΙ ΠΛΗΡΩΜΗΣ </h2>
        <p>Διαβάστε εδώ για τους τρόπους πληρωμής</p>
        <h2>4. ΑΣΦΑΛΕΙΑ ΣΥΝΑΛΛΑΓΩΝ </h2>
        <p>
          Όλες οι συναλλαγές που πραγματοποιείτε μέσω του nicolasjewelry.gr
          διέπονται από το Διεθνές και Ευρωπαϊκό δίκαιο, που ρυθμίζει θέματα
          σχετικά με το ηλεκτρονικό εμπόριο, καθώς επίσης και από το Νόμο περί
          προστασίας των καταναλωτών (Ν. 2251/1994), που ρυθμίζει θέματα σχετικά
          με τις πωλήσεις από απόσταση. 100% Ασφάλεια Ηλεκτρονικών Συναλλαγών.
          Σε περίπτωση που επιλέξετε να πληρώσετε με την πιστωτική σας κάρτα, η
          τεχνολογία του PayPal εγγυάται ένα εντελώς ασφαλές περιβάλλον
          συναλλαγών, χωρίς επιπλέον επιβάρυνση για εσάς.
        </p>
        <p>
          Οι πληροφορίες σχετικά με την πιστωτική σας κάρτα είναι 100%
          προστατευμένες και δεν αποκαλύπτονται σε κανέναν, ούτε και στο
          nicolasjewelry.gr.
        </p>
        <h2>5. ΕΠΙΣΤΡΟΦΕΣ ΠΡΟΪΟΝΤΩΝ </h2>
        <p>
          Σκοπός του nicolasjewelry.gr είναι κάθε παραγγελία να σας ικανοποιεί
          100%. Γι’ αυτό και σας δίνουμε την δυνατότητα να επιστρέψετε όποιο
          προϊόν επιθυμείτε με μία εύκολη και γρήγορη διαδικασία επιστροφής
          χρημάτων.{' '}
          <p>
            <strong>
              Προσοχή. Αφορά μόνο το ηλεκτρονικό κατάστημα και όχι τα φυσικά
              καταστήματα
            </strong>
          </p>
        </p>
        <strong>Επιστροφές προϊόντων με χρέωση της επιχείρησης</strong>
        <p>
          Οι πελάτες μας έχουν το δικαίωμα να επιστρέψουν τα προϊόντα που
          αγόρασαν από το παρόν ηλεκτρονικό κατάστημα nicolasjewelry.gr όταν
          πρόκειται για: (1) προϊόντα που καταστράφηκαν κατά την μεταφορά, με
          κακή συσκευασία κλπ. (2) λάθος προϊόντα π.χ. προϊόντα που δεν είχαν
          παραγγελθεί και στάλθηκαν λανθασμένα με υπαιτιότητα του
          nicolasjewelry.gr.
        </p>
        <p>
          Σε περίπτωση που ο πελάτης μας έχει παραλάβει το ελαττωματικό ή λάθος
          προϊόν, θα πρέπει να το παραδώσει στον εκπρόσωπο της εταιρίας
          ταχυμεταφορών που θα του φέρει το προϊόν αντικατάστασης. Το προϊόν θα
          πρέπει να επιστρέφεται στην κατάσταση που παραδόθηκε. Το ανώτατο
          χρονικό περιθώριο επιστροφής δεν δύναται να ξεπερνά τις δεκατέσσερις
          (14) ημερολογιακές ημέρες από την ημερομηνία παραλαβής.
        </p>
        <p>
          <strong>
            Δεν γίνονται αλλαγές στα εποχιακά είδη (πχ γούρια) και στις ειδικές
            παραγγελίες (πχ ονόματα κολιέ με οικογένειες και μονογράμματα)
          </strong>
        </p>
        <p>
          <strong>Επιστροφές των προϊόντων με χρέωση του πελάτη</strong>
        </p>
        <p>
          Ο πελάτης μας διατηρεί το δικαίωμα να επιστρέψει τα προϊόντα που
          αγόρασε, αζημίως και χωρίς υποχρέωση ανακοίνωσης του λόγου για τον
          οποίο επιθυμεί την επιστροφή των προϊόντων, μέσα σε δεκατέσσερις (14)
          ημερολογιακές ημέρες από την ημερομηνία παραλαβής. Στην περίπτωση αυτή
          η επιστροφή γίνεται με τρόπο που επιλέγετε εσείς, και αναλαμβάνετε τα
          μεταφορικά έξοδα της επιστροφής
        </p>
        <p>
          Αυτό που εμείς ζητάμε είναι να επιστραφούν τα προϊόντα στην αρχική
          τους συσκευασία και αχρησιμοποίητα, μέσα σε 14 ημερολογιακές ημέρες
          από την ημερομηνία παραλαβής της παραγγελίας. Ο πελάτης θα πρέπει
          απαραίτητα να επικοινωνήσει με το nicolasjewelry.gr για να μας
          ενημερώσει γραπτά για τον τραπεζικό λογαριασμό ή λογαριασμό πιστωτικής
          κάρτας στον οποίο θα γίνει η επιστροφή του ποσού. Η επιστροφή των
          χρημάτων που αντιστοιχεί στα προϊόντα που παρήγγειλε ο πελάτης και θα
          ολοκληρωθεί εντός 14 ημερών από την ημερομηνία που το
          nicolasjewelry.gr θα παραλάβει τα επιστραφέντα προϊόντα.
        </p>
      </TextLayoutContainer>
    </Container>
  </Layout>
);

export default TermsPage;
